import React, { forwardRef } from "react";
import styles from "./styles.module.css";

const CustomInput = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      backgroundColor = "rgba(108, 99, 255, 0.1)",
      width = "350px",
      margin = "15px 0",
      borderRadius = "16px",
      name = "",
      type = "text",
      error = false,
    },
    ref
  ) => {
    return (
      <div
        className={`${styles.customInput} ${
          !!value?.length && styles.hasValue
        }`}
        style={{ width: width, margin: margin, borderRadius: borderRadius }}
      >
        <input
          name={name}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target)}
          style={{
            backgroundColor: backgroundColor,
            width: width,
            borderRadius: borderRadius,
          }}
          ref={ref}
          maxLength="11"
        />
        <label>{placeholder}</label>
        {error && (
          <p className="text-red">
            Personal number should contain eleven digit
          </p>
        )}
      </div>
    );
  }
);

export default CustomInput;
